<template>
  <div>
    <div class="course" v-for="(item, index) in data" :key="`${item.courseId}${index}`">
      <el-form label-width="140px">
        <el-form-item label="申请人ID">
          <!-- <el-input v-model="item.publisherPhoneNumber" :disabled="true" style="width: 300px"></el-input> -->
          <div class="params">{{ item.publisherPhoneNumber }}</div>
        </el-form-item>
        <el-form-item label="产品名称">
          <p @click="checkCourse(item)" style="cursor: pointer;color: #007aff;">{{ item.courseName }}</p>
        </el-form-item>
        <el-form-item
          v-if="['重新发布', '发布'].includes(item.action)"
          :label="`${item.action === '发布' ? '视频 Url' : item.action === '重新发布' ? '新视频 Url' : ''}`"
        >
          <p @click="checkVideo(item)" style="cursor: pointer;color: #007aff;">查看</p>
        </el-form-item>
        <el-form-item label="动作">
          <!-- <el-input v-model="item.action" :disabled="true" style="width: 300px"></el-input> -->
          <div class="params">{{ item.action }}</div>
        </el-form-item>
        <el-form-item label="小图" v-if="['上传小图', '更新小图', '发布'].includes(item.action)">
          <img :src="item.previewPicUrl" style="width: 100px;" @click="previewPicUrl(item)" />
        </el-form-item>
        <el-form-item label="提交时间">
          <!-- <el-input v-model="item.createTime" :disabled="true" style="width: 300px"></el-input> -->
          <div class="params">{{ item.createTime }}</div>
        </el-form-item>
        <el-form-item label="体量" v-if="['重新发布', '发布'].includes(item.action)">
          <el-select
            v-model="item.priceRankSizeId"
            placeholder="请选择"
            style="margin-right: 10px;width: 300px"
            @change="
              id => {
                select(index, id, 'rank', item)
              }
            "
          >
            <el-option
              :label="`${item.name}：${item.description}，${item.value}元`"
              :value="item.id"
              v-for="item in courseVolumes"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="质量" v-if="['重新发布', '发布'].includes(item.action)">
          <el-select
            v-model="item.priceRankQualityId"
            style="margin-right: 10px;width: 300px"
            placeholder="请选择"
            @change="
              id => {
                select(index, id, 'quality', item)
              }
            "
          >
            <el-option
              :label="`${item.name}：${item.description}，${item.value}`"
              :value="item.id"
              v-for="item in courseQuality"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格" v-if="['重新发布', '发布'].includes(item.action)">
          <p>{{ item.price || getPrice(item, index) }} 元</p>
        </el-form-item>
        <el-form-item label="制作费档位" v-if="['重新发布', '发布'].includes(item.action)">
          <el-select v-model="item.authorFeeRank" style="margin-right: 10px;width: 300px" placeholder="请选择">
            <el-option :label="item" :value="item" v-for="item in authorFeeRankList" :key="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="制作费金额"
          v-if="item.authorFeeRank !== '无' && ['重新发布', '发布'].includes(item.action)"
        >
          <el-input v-model="item.authorFeeAmount" style="width: 300px"></el-input> 元
        </el-form-item>
        <el-form-item label="创作分佣" v-if="['重新发布', '发布'].includes(item.action)">
          <el-select v-model="item.authorCommission" style="margin-right: 10px;width: 300px" placeholder="请选择">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in authorCommissionList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拒绝原因" v-if="item.status !== 2">
          <el-input v-model="item.refuseReason" style="width: 300px" v-if="item.status === 1"></el-input>
          <div class="params" v-if="item.status === 3">{{ item.refuseReason }}</div>
        </el-form-item>
        <el-form-item label="状态">
          <el-tag v-if="item.status === 1" style="color: rgb(69, 204, 180)">待审核</el-tag>
          <el-tag v-if="item.status === 2" style="color: rgb(69, 204, 180);">已通过</el-tag>
          <el-tag v-if="item.status === 3" type="danger">已拒绝</el-tag>
        </el-form-item>
        <el-form-item label="审核人">
          <div class="params">{{ item.auditUserPhoneNumber }}</div>
        </el-form-item>

        <el-form-item
          label="合规审核"
          v-if="!['删除', '上架', '下架'].includes(item.action) && !['上传小图', '更新小图'].includes(item.action)"
        >
          <el-tag
            v-if="item.complianceStatus === 0"
            style="color: rgb(79, 194, 43);background-color:rgb(237, 253, 228);"
            >待审核</el-tag
          >
          <el-tag
            v-if="item.complianceStatus === 1"
            style="color: rgb(79, 194, 43);background-color:rgb(237, 253, 228);"
            >通过</el-tag
          >
          <el-tag v-if="item.complianceStatus === 2" type="danger">拒绝</el-tag>
          <el-tag v-if="item.complianceStatus === 3">需人工审核</el-tag>
        </el-form-item>
        <el-form-item v-if="item.status === 1">
          <el-button type="primary" @click="pass(item)">通过</el-button>
          <el-button @click="cancel(item)" type="danger">拒绝</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {priceRankGroup, coursePassed, courseRejected} from '@/api/project'

export default {
  props: ['data'],
  data() {
    return {
      courseQuality: [],
      courseVolumes: [],
      authorFeeRankList: ['无', '少许', '中等', '大部分', '全部'],
      authorCommissionList: [
        {
          name: '无',
          id: '-1'
        },
        {
          name: '通用分佣方式',
          id: '0'
        },
        {
          name: '5%',
          id: '0.05'
        },
        {
          name: '10%',
          id: '0.1'
        },
        {
          name: '15%',
          id: '0.15'
        },
        {
          name: '20%',
          id: '0.2'
        },
        {
          name: '25%',
          id: '0.25'
        },
        {
          name: '30%',
          id: '0.3'
        },
        {
          name: '35%',
          id: '0.35'
        },
        {
          name: '40%',
          id: '0.4'
        },
        {
          name: '45%',
          id: '0.45'
        },
        {
          name: '50%',
          id: '0.5'
        },
        {
          name: '55%',
          id: '0.55'
        },
        {
          name: '60%',
          id: '0.6'
        },
        {
          name: '65%',
          id: '0.65'
        },
        {
          name: '70%',
          id: '0.7'
        },
        {
          name: '75%',
          id: '0.75'
        },
        {
          name: '80%',
          id: '0.8'
        },
        {
          name: '85%',
          id: '0.85'
        },
        {
          name: '90%',
          id: '0.9'
        },
        {
          name: '95%',
          id: '0.95'
        },
        {
          name: '100%',
          id: '1'
        }
      ]
    }
  },
  created() {
    priceRankGroup().then(res => {
      this.courseQuality = res.data.courseQuality
      this.courseVolumes = res.data.courseVolumes
    })
  },
  methods: {
    checkCourse(info) {
      this.$store.commit('ChangeSearchInput', '')
      this.$router.push({
        path: '/techVideoDetail',
        query: {id: info.courseId}
      })
    },
    checkVideo(item) {
      this.$router.push(`/techVideoShow?cloudFileId=${item.cloudFileId}&appId=${item.appId}&psign=${item.psign}`)
      // window.open(item.cloudFileUrl, '_blank')
    },
    previewPicUrl(item) {
      window.open(item.previewPicUrl, '_blank')
    },
    select(index, id, type, item) {
      if (type === 'quality') {
        if (!item.priceRankSizeId) return
        const priceRankSizeIdObj = this.courseVolumes.find(itm => itm.id === item.priceRankSizeId)
        const priceRankQualityIdObj = this.courseQuality.find(itm => id === itm.id)
        if (priceRankSizeIdObj && priceRankQualityIdObj) {
          this.$set(
            this.data[index],
            'price',
            (
              Number(priceRankSizeIdObj.value.toString().replace('元', '')) *
              Number(priceRankQualityIdObj.value.toString().replace('元', ''))
            ).toFixed(2)
          )
        }
      }
      if (type === 'rank') {
        if (!item.priceRankQualityId) return
        const priceRankSizeIdObj = this.courseVolumes.find(itm => id === itm.id)
        const priceRankQualityIdObj = this.courseQuality.find(itm => itm.id === item.priceRankQualityId)
        if (priceRankSizeIdObj && priceRankQualityIdObj) {
          this.$set(
            this.data[index],
            'price',
            (
              priceRankSizeIdObj.value.toString().replace('元', '') *
              priceRankQualityIdObj.value.toString().replace('元', '')
            ).toFixed(2)
          )
        }
      }
    },
    getPrice(info, index) {
      if (!info.priceRankSizeId) return
      if (!info.priceRankQualityId) return
      const priceRankSizeIdObj = this.courseVolumes.find(itm => itm.id === info.priceRankSizeId)
      const priceRankQualityIdObj = this.courseQuality.find(itm => itm.id === info.priceRankQualityId)
      if (priceRankSizeIdObj && priceRankQualityIdObj) {
        this.$set(
          this.data[index],
          'price',
          (
            Number(priceRankSizeIdObj.value.toString().replace('元', '')) *
            Number(priceRankQualityIdObj.value.toString().replace('元', ''))
          ).toFixed(2)
        )
      }
    },
    cancel(info) {
      if (!info.refuseReason) {
        this.$message.error('请输入拒绝原因')
        return
      }
      courseRejected({
        applyId: info.applyId, //申请id
        refuseReason: info.refuseReason //拒绝原因
      }).then(res => {
        this.$emit('getData')
        this.$message.success(`操作成功`)
      })
    },

    // coursePassed, courseRejected
    pass(info) {
      if (info.action === '发布') {
        if (info.priceRankSizeId === '') {
          this.$message.error('请选择体量,来设置价格')
          return
        }
        if (info.priceRankQualityId === '') {
          this.$message.error('请选择质量,来设置价格')
          return
        }
        if (info.authorFeeRank === '') {
          this.$message.error('请选择制作费档位')
          return
        }
        if (info.authorCommission === '') {
          this.$message.error('请选择创作分佣')
          return
        }
      }
      if (info.complianceStatus === 1 || !['重新发布', '发布'].includes(info.action)) {
        coursePassed({
          applyId: info.applyId, //申请id
          priceRankSizeId: info.priceRankSizeId, //拒绝原因
          authorCommission: info.authorCommission, //拒绝原因
          priceRankQualityId: info.priceRankQualityId, //拒绝原因
          authorFeeRank: info.authorFeeRank, //拒绝原因
          authorFeeAmount: info.authorFeeAmount //拒绝原因
        }).then(res => {
          if (res.code === 200) {
            this.$emit('getData')
            this.$message.success(`操作成功`)
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.$alert('', '当前申请合规审核未通过，仍然确定要通过吗？', {
          confirmButtonText: '确定',
          callback: action => {
            if (action === 'confirm') {
              coursePassed({
                applyId: info.applyId, //申请id
                priceRankSizeId: info.priceRankSizeId, //拒绝原因
                authorCommission: info.authorCommission, //拒绝原因
                priceRankQualityId: info.priceRankQualityId, //拒绝原因
                authorFeeRank: info.authorFeeRank, //拒绝原因
                authorFeeAmount: info.authorFeeAmount //拒绝原因
              }).then(res => {
                if (res.code === 200) {
                  this.$emit('getData')
                  this.$message.success(`操作成功`)
                } else {
                  this.$message.error(res.msg)
                }
              })
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.course {
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
}
.el-form-item {
  margin-bottom: 12px;
}
/deep/ .el-form-item__label {
  padding: 0 32px 0 0;
}
</style>
